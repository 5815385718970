<template>
  <div>
    <el-progress :percentage="num"></el-progress>
  </div>
</template>

<script>
import { instance } from '@/utils/no_loading.js'
export default {
  data () {
    return {
      num: 0,
    }
  },
  created () {
  },
  props: ['url'],
  methods: {
    upFile (file) {
      instance({
        url: this.url,
        method: 'post',
        data: file,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
            if (complete >= 100) {
              complete = 99
            }
            this.num = complete
          }
        }
      }).then(result => {
        // todo
        this.num = 100
        this.$notify({
          title: '提示',
          message: '上传成功!',
          type: 'success'
        });
        this.$emit('handleClose')
      })
    }
  },
}
</script>