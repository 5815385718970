<template>
  <div>
    <el-dialog title="上传微课"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="600px"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="选择微课">
              <el-button type="primary"
                         v-if="fileList.length == 0"
                         @click="checkFile">选择文件</el-button>
              <div v-else>
                <el-tag closable
                        @close="clear"
                        type="primary">
                  {{fileList[0].name}}
                </el-tag>
                <uploadProgress ref="uploadProgressRef"
                                @handleClose="success"
                                url="/api/v1/course/create" />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="描述">
              <el-input clearable
                        type="textarea"
                        :rows="3"
                        placeholder="请输入描述"
                        v-model="form.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="审核人"
                        prop="edu_user_id">
            <el-select v-model="form.audit_ids"
                       filterable
                       multiple
                       style="width:100%"
                       placeholder="请选择审核人">
              <el-option v-for="item in auditTeacherList"
                         :key="item.edu_user_id"
                         :label="`${item.edu_user_mobile} ${item.edu_user_nickname}`"
                         :value="item.edu_user_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>

      </el-form>
      <el-row style="text-align:center">
        <el-button type="primary"
                   @click="save">确 认</el-button>
      </el-row>
      <video id="videoPlayerNew"
             :src="videoUrl"
             v-show="false"
             controls></video>
    </el-dialog>
  </div>
</template>

<script>
import uploadProgress from '@/components/uploadPregress/index.vue'
export default {
  data () {
    return {
      form: {},
      dialogVisible: false,
      fileList: [],
      auditTeacherList: [],
      videoUrl: ''
    }
  },
  components: {
    uploadProgress
  },
  methods: {
    getAuditTeacherList (id) {
      this.$http({
        url: '/api/v1/course/appoint_audit_lst',
        method: 'get',
        params: {
          subject_id: id
        }
      }).then(res => {
        this.auditTeacherList = res.data
      })
    },
    handleClose () {
      this.dialogVisible = false
      this.fileList = []
    },
    success () {
      this.handleClose()
      this.$parent.search
    },
    save () {
      var vid = document.getElementById("videoPlayerNew");

      if (this.fileList.length == 0) {
        this.$message.warning('请选择文件')
        return
      }
      let formData = new FormData()
      formData.append('video', this.fileList[0])

      formData.append('length', vid.duration)//打印时长
      //  指定上传文件的字段名称
      formData.append('filename', 'video')
      formData.append('audit_ids', this.form.audit_ids)
      formData.append('question_id', this.form.question_id)
      formData.append('description', this.form.description)

      let form = {
        length: vid.duration,
        filename: 'video',
        audit_ids: this.form.audit_ids,
        question_id: this.form.question_id,
        description: this.form.description,
      }
      formData.append('sign', this.$getMd5Form(form))
      this.$refs.uploadProgressRef.upFile(formData)
    },
    checkFile () {
      this.$get_file({ limit: 100, fileType: 'video/mp4,video/3gpp,video/mpeg,video/ogg' }).then(res => {
        this.fileList.push(res)
        this.videoUrl = URL.createObjectURL(res)
      })
    },
    clear () {
      this.fileList = []
    }
  }
}
</script>
