<template>
  <div>
    <el-form :model="form"
             label-width="60px">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="科目">
            <el-select style="width:100%"
                       v-model="form.subjectId"
                       clearable
                       @change="clearForm(2)"
                       placeholder="选择科目">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="来源">
            <el-select style="width:100%"
                       v-model="form.sourceId"
                       filterable
                       clearable
                       placeholder="选择来源">
              <el-option v-for="item in sourceList"
                         :key="item.source_id"
                         :label="item.source_name"
                         :value="item.source_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-input clearable
                    placeholder="题目id"
                    type="number"
                    @keyup.enter.native="search"
                    v-model="form.questionId" />
        </el-col>
        <el-col :span="9">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="checkItem()"
                     v-if="isComponent">确定题目</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table border
              stripe
              :data="tableData"
              @row-click="clickRow"
              highlight-current-row
              :max-height="$store.state.tableHeight + 30"
              style="width: 100%">
      <el-table-column prop="question_id"
                       v-if="tableColumns[0].isShow"
                       width="90"
                       align="center"
                       label="题目ID" />
      <el-table-column prop="source_name"
                       v-if="tableColumns[1].isShow"
                       align="center"
                       label="题目来源" />
      <el-table-column prop="question_type_name"
                       v-if="tableColumns[2].isShow"
                       align="center"
                       label="题目类型" />
      <el-table-column prop="subject_name"
                       v-if="tableColumns[3].isShow"
                       align="center"
                       label="科目" />
      <el-table-column prop="question_score"
                       v-if="tableColumns[4].isShow"
                       align="center"
                       label="分数" />
      <el-table-column prop="question_category_name"
                       v-if="tableColumns[5].isShow"
                       align="center"
                       label="题目类别" />
      <el-table-column align="center"
                       v-if="tableColumns[6].isShow"
                       label="是否分派">
        <template slot-scope="{row}">
          <div :class="getClass(row)">{{row.task_id == 0?'未分派':'已分派'}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="created_time"
                       v-if="tableColumns[7].isShow"
                       align="center"
                       label="操作">
        <template slot="header">
          <i class="el-icon-setting"
             slot="header"
             @click="showController"></i>
          操作
        </template>
        <template slot-scope="{row}">
          <div v-if="!isComponent">
            <div class="operyion_btn_icon">
              <el-tooltip effect="dark"
                          content="查看题目"
                          placement="top-start">
                <img src="@/assets/icons/icon-look@2x.png"
                     @click="showDialog(row)"
                     alt="">
              </el-tooltip>
              <span style="width:20px;height:20px"></span>
              <el-tooltip effect="dark"
                          content="上传微课"
                          placement="top-start">
                <img src="@/assets/icons/icon-upVideo@2x.png"
                     @click="checkFile(row)"
                     alt="">
              </el-tooltip>
              <span style="width:20px;height:20px"></span>
              <el-tooltip effect="dark"
                          content="分派教师"
                          placement="top-start">
                <img src="@/assets/teacher_module/weitongguo.png"
                     @click="fenpaiTeacher(row)"
                     alt="">
              </el-tooltip>
            </div>
          </div>
          <div v-else>
            <div class="operyion_btn_icon">
              <img src="@/assets/icons/icon-look@2x.png"
                   @click="showDialog(row)"
                   alt="">
              <span style="width:20px;height:20px"></span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Shuttle ref="ShuttleRef" />

    <InfoForm ref="InfoFormRef" />
    <uploadForm ref="uploadFormRef" />
    <fenpei ref="fenpeiRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import InfoForm from '@/components/checkInfo/index.vue'
import uploadForm from './uploadFile.vue'
import fenpei from './fenpai.vue'
export default {
  data () {
    return {
      form: {
        subjectId: '',
        sourceId: '',
        questionId: ''
      },
      // 存储高亮的数据行的时候传递父组件更新微课绑定的题目
      editForm: {},
      subjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      sourceList: [],
      pageName: 'cz_microlecture_topicList_admin',
      tableColumns: [
        { label: '题目ID', isShow: true },
        { label: '题目来源', isShow: true },
        { label: '题目类型', isShow: true },
        { label: '科目', isShow: true },
        { label: '分数', isShow: true },
        { label: '题目类别', isShow: true },
        { label: '是否分派', isShow: true },
        { label: '操作', isShow: true },
      ]
    }
  },
  props: {
    isComponent: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    }
  },
  components: {
    InfoForm, uploadForm, fenpei
  },
  created () {
    var info = window.localStorage.getItem(this.pageName)
    if (info) {
      this.tableColumns = JSON.parse(info)
    }
  },
  mounted () {
    this.getSubject()
  },
  methods: {
    showDialog (item) {
      let url = '/microlecture/topicList'
      if (this.url) {
        url = this.url
      }
      this.$router.push(url + '?id=' + item.question_id)
      this.$refs.InfoFormRef.dialogVisible = true
    },
    checkFile (row) {
      if (row.task_id) {
        this.$notify({
          title: '提示',
          message: '已分派题目无法上传!',
          type: 'warning'
        });
        return
      }
      let form = {
        question_id: row.question_id,
        subject_id: row.subject_id,
        source_id: row.source_id,
        audit_ids: [],
        description: ''
      }
      this.$refs.uploadFormRef.form = form
      this.$refs.uploadFormRef.getAuditTeacherList(row.subject_id)
      this.$refs.uploadFormRef.dialogVisible = true

    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.subject
        // this.getSourceList()
      })
    },
    initData (page, limit) {
      this.editForm = {}
      this.form.page = page
      this.form.limit = limit
      this.$http({
        url: '/api/v1/course/question',
        method: 'post',
        data: this.form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    clearForm () {
      if (this.form.subjectId == '') {
        this.form.sourceId = ''
        this.sourceList = []

        return
      }
      this.getSourceList()
    },
    checkItem (item) {
      if (JSON.stringify(this.editForm) == '{}') {
        this.$message.warning('请选择题目')
        return
      }
      this.$emit('checkItem', this.editForm)
    },
    clickRow (item) {
      this.editForm = item
    },
    getSourceList () {
      this.$http({
        url: '/api/v1/question/choices',
        method: 'POST',
        data: {
          subject_id: this.form.subjectId
        }
      }).then(res => {
        this.sourceList = res.data.paper_source
      })
    },
    fenpaiTeacher (item) {
      if (item.task_id) {
        this.$notify({
          title: '提示',
          message: '该题已分派!',
          type: 'warning'
        });
        return
      }
      let form = {
        edu_user_id: '',
        enend_time: '',
        question_id: item.question_id,
        audit_ids: []
      }
      this.$refs.fenpeiRef.form = form
      this.$refs.fenpeiRef.getTeacher()
      this.$refs.fenpeiRef.getAuditTeacherList(item.subject_id)
      this.$refs.fenpeiRef.dialogVisible = true
    },
    getClass (row) {
      return row.task_id == 0 ? 'notpass' : 'pass'
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    }

  }
}
</script>

<style lang="scss" scoped>
</style>