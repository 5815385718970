import axios from 'axios'
import basicUrl from './basicUrl'
import router from '@/router'

import { Message } from 'element-ui'

export const instance = axios.create({
  baseURL: basicUrl,
  timeout: 60000,
});

export const http_file = axios.create({
  baseURL: basicUrl,
  timeout: 60000,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  transformRequest: [(data) => {
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    return formData
  }]
})

function getTime () {
  if (window.localStorage.getItem('userInfo')) {
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    var time = Date.parse(new Date()).toString(); //精确到毫秒
    time = time.substr(0, 10); //精确到秒
    if (userInfo) {
      if (userInfo.exp - time <= 180) {
        window.localStorage.clear()
        Message.error('登录已过期，请重新登录')
        router.push('/login')
      }
    }
  }
}

function addInterceptors (face) {
  face.interceptors.request.use(config => {
    getTime()
    if (window.localStorage.getItem('userInfo')) {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      config.headers['Authorization'] = `Bearer ${userInfo.token}`
    }
    // if (config.headers['Content-Type'] == 'multipart/form-data') {
    //   console.log(config);
    //   return face
    // }
    return config
  })
  face.interceptors.response.use(async config => {
    // status 只有400
    config = config.data
    return await new Promise((resolve, reject) => {
      if (config.status == 200) {
        resolve(config)
      } else {
        // 当状态码不为200的时候，直接拦截
        if (config.msg == 'error') {
          Message.error(config.data.msg)
        } else {
          Message.error(config.msg)
        }
        if (config.status == 410000) {
          window.localStorage.clear()
          router.push('/login')
        }
        reject(config)
      }
    })
  })
}

addInterceptors(instance)
addInterceptors(http_file)