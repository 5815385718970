<template>
  <div>
    <el-dialog title="查看题目"
               v-if="dialogVisible"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="60%"
               :before-close="handleClose">
      <infoForm />

      <el-dialog width="300px"
                 title="拒绝原因"
                 :visible.sync="innerVisible"
                 append-to-body>
        <el-row>
          <el-input type="textarea"
                    placeholder="请输入拒绝原因"
                    v-model="reason"></el-input>
        </el-row>
        <el-row style="text-align:center;margin-top:15px">
          <el-button type="primary"
                     @click="submit">确认</el-button>
        </el-row>
      </el-dialog>
      <slot name="button"></slot>
    </el-dialog>
  </div>
</template>

<script>
import infoForm from '../../views/myFavorite/components/index.vue'
export default {
  data () {
    return {
      dialogVisible: false,
      innerVisible: false,
      reason: ''
    }
  },
  components: {
    infoForm
  },
  methods: {
    handleClose () {
      document.getElementsByClassName('view_warp')[0].style.background = ''
      document.getElementsByClassName('view_warp')[0].style.padding = '15px'
      this.innerVisible = false
      this.reason = ''
      this.dialogVisible = false
    },
    submit () {
      this.$parent.postData(2, this.reason)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.btn_warp {
  width: 100%;
  display: flex;
  justify-content: center;
  .btn {
    padding: 5px 30px;
    border-radius: 15px;
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
  }
  .primary {
    background: #3f9eff;
  }
  .danger {
    background: #fe765f;
  }
}
</style>